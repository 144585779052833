import { HttpInterceptorFn } from '@angular/common/http';
import { AppStorage } from '../enums/app-storage';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem(AppStorage.token) || '';
  req = req.clone({
    headers: req.headers
      .set('Authorization', `Bearer ${token}`)
      .set('Accept-Language', 'en'),
  });

  return next(req);
};
