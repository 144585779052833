import { Component, effect, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AlertInlineComponent, NavbarComponent } from '@kuiper/ui-components';
import { ToasterService } from './common/services/toast/toast.service';
import { Toast } from './common/models/toast.model';
import { AuthService } from './common/services/auth/auth.service';

@Component({
  standalone: true,
  imports: [RouterModule, NavbarComponent, AlertInlineComponent],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  toastData = signal<Toast>({
    alertData: {},
    hasCloseIcon: true,
    hasIcon: false,
  });
  showToast = signal<boolean>(false);
  showNotification = signal<boolean>(false);

  constructor(
    private readonly toastService: ToasterService,
    private readonly authService: AuthService
  ) {
    // this.showNotification.set(this.authService.isAuthenticated());
    effect(
      () => {
        this.toastData.set(this.toastService.toasterData());
        this.showToast.set(this.toastService.showToast());
        this.showNotification.set(this.authService.isLoggedIn());
      },
      { allowSignalWrites: true }
    );
  }

  onCloseToast($event: boolean) {
    this.toastService.showToast.set($event);
    this.toastService.resetInterval();
  }
}
