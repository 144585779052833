import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { ToasterService } from '../services/toast/toast.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { ManageCookiesService } from '../services/cookies-service/manage-cookies.service';
import { AppStorage } from '../enums/app-storage';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const toastService = inject(ToasterService);
  const router = inject(Router);
  const cookiesService = inject(ManageCookiesService);
  return next(req).pipe(
    catchError((httpError: HttpErrorResponse) => {
      const error = httpError.error;
      if (httpError.status == 401) {
        cookiesService.deleteCookie(AppStorage.token);
        router.navigate(['/auth/login']);
      } else if (httpError.status == 403) {
        toastService.add(
          error.message,
          '',
          'assets/images/icons/danger.svg',
          'error'
        );
        // cookiesService.deleteCookie(AppStorage.token);
        // router.navigate(['/auth/login']);
      } else if (httpError.status != 422) {
        toastService.add(
          error.message,
          '',
          'assets/images/icons/danger.svg',
          'error',
          true,
          false
        );
      }
      return throwError(error);
    })
  );
};
