<lib-navbar [hasNotification]="false"></lib-navbar>
<main class="at-w-100">
  <router-outlet></router-outlet>
</main>

@if (showToast()) {
<lib-alert-inline
  [alertData]="toastData().alertData"
  [hasCloseIcon]="toastData().hasCloseIcon"
  [hasIcon]="toastData().hasIcon"
  (onAlertclose)="onCloseToast($event)"
  class="toast"></lib-alert-inline>
}
