import { CanDeactivateFn } from '@angular/router';
import { Observable } from 'rxjs';

// Interface for components using this guard
export interface CanDeactivateBudgetComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export const canDeactivateBudgetGuard: CanDeactivateFn<
  CanDeactivateBudgetComponent
> = component => {
  return component.canDeactivate ? component.canDeactivate() : true;
};
