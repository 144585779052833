import { Route } from '@angular/router';
import { canDeactivateBudgetGuard } from '../common/guards/can-deactivate-budget/can-deactivate-budget.guard';

export const employeeRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests',
  },
  {
    path: 'requests',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request-list/request-list.component'
      );
      return c.RequestListComponent;
    },
  },
  {
    path: 'requests/:id',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request-details/request-details.component'
      );
      return c.RequestDetailsComponent;
    },
  },
  {
    path: 'requests/:requestId/:requestAction',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request.component'
      );
      return c.RequestComponent;
    },
  },
  {
    path: 'requests/request/:requestAction/:flowId',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request-view/request-view.component'
      );
      return c.RequestViewComponent;
    },
  },
  {
    path: 'home',
    async loadComponent() {
      const c = await import('../pages/employee-flow/home/home.component');
      return c.HomeComponent;
    },
  },
  {
    path: 'my-inbox',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/my-inbox/my-inbox.component'
      );
      return c.MyInboxComponent;
    },
  },
  {
    path: 'my-inbox/:stepId/:requestAction',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request.component'
      );
      return c.RequestComponent;
    },
  },
  {
    path: 'my-inbox/request/:requestAction/:flowId',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/request/request-view/request-view.component'
      );
      return c.RequestViewComponent;
    },
  },
  {
    path: 'projects',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/projects/project-list.component'
      );
      return c.ProjectListComponent;
    },
  },
  {
    path: 'projects/project-form',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/projects/project-form/project-form.component'
      );
      return c.ProjectFormComponent;
    },
  },
  {
    path: 'projects/project-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/projects/project-form/project-form.component'
      );
      return c.ProjectFormComponent;
    },
  },
  {
    path: 'projects/:id',
    canDeactivate: [canDeactivateBudgetGuard],
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/projects/project-details/project-details.component'
      );
      return c.ProjectDetailsComponent;
    },
  },
  {
    path: 'vendors',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/vendor/vendor-list/vendor-list.component'
      );
      return c.VendorListComponent;
    },
  },
  {
    path: 'vendors/vendor-form',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/vendor/vendor-form/vendor-form.component'
      );
      return c.VendorFormComponent;
    },
  },
  {
    path: 'vendors/vendor-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/vendor/vendor-form/vendor-form.component'
      );
      return c.VendorFormComponent;
    },
  },
  {
    path: 'vendors/:id',
    async loadComponent() {
      const c = await import(
        '../pages/employee-flow/vendor/vendor-details/vendor-details.component'
      );
      return c.VendorDetailsComponent;
    },
  },
];
