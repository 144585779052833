import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppStorage } from '../enums/app-storage';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const isTokenPresent = !!localStorage.getItem(AppStorage.token);

  if (!isTokenPresent) {
    router.navigate(['/auth/login']);
  }

  return isTokenPresent;
};
