import { Route } from '@angular/router';

export const mainRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests',
  },
  {
    path: 'requests',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-list/request-list.component'
      );
      return c.RequestListComponent;
    },
  },
  {
    path: 'requests/:id',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-details/request-details.component'
      );
      return c.RequestDetailsComponent;
    },
  },
  {
    path: 'requests/:requestId/:requestAction',
    async loadComponent() {
      const c = await import('../pages/main/request/request.component');
      return c.RequestComponent;
    },
  },
  {
    path: 'requests/request/:requestAction/:flowId',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-view/request-view.component'
      );
      return c.RequestViewComponent;
    },
  },
  {
    path: 'home',
    async loadComponent() {
      const c = await import('../pages/main/home/home.component');
      return c.HomeComponent;
    },
  },
  {
    path: 'my-inbox',
    async loadComponent() {
      const c = await import('../pages/main/my-inbox/my-inbox.component');
      return c.MyInboxComponent;
    },
  },
  {
    path: 'my-inbox/:stepId/:requestAction',
    async loadComponent() {
      const c = await import('../pages/main/request/request.component');
      return c.RequestComponent;
    },
  },
  {
    path: 'my-inbox/request/:requestAction/:flowId',
    async loadComponent() {
      const c = await import(
        '../pages/main/request-view/request-view.component'
      );
      return c.RequestViewComponent;
    },
  },
  {
    path: 'projects',
    async loadComponent() {
      const c = await import('../pages/main/projects/project-list.component');
      return c.ProjectListComponent;
    },
  },
  {
    path: 'projects/project-form',
    async loadComponent() {
      const c = await import(
        '../pages/main/projects/project-form/project-form.component'
      );
      return c.ProjectFormComponent;
    },
  },
  {
    path: 'projects/project-form/:id',
    async loadComponent() {
      const c = await import(
        '../pages/main/projects/project-form/project-form.component'
      );
      return c.ProjectFormComponent;
    },
  },
  {
    path: 'projects/:id',
    async loadComponent() {
      const c = await import(
        '../pages/main/projects/project-details/project-details.component'
      );
      return c.ProjectDetailsComponent;
    },
  },
];
