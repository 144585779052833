import { Route } from '@angular/router';

export const adminRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'requests',
  },
  {
    path: 'home',
    async loadComponent() {
      const c = await import('../pages/admin-flow/home/home.component');
      return c.HomeComponent;
    },
  },
  {
    path: 'departments',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/departments.component'
      );
      return c.DepartmentsComponent;
    },
  },
  {
    path: 'departments/department-form',
    async loadComponent() {
      const c = await import(
        '../pages/admin-flow/departments/department-form/department-form.component'
      );
      return c.DepartmentFormComponent;
    },
  },
];
